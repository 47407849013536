import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout/Layout';
import { StripType } from '../types';
import styles from './smartpay-shops.module.scss';

interface Props {
  data: {
    contentful: {
      stripCollection: { items: StripType[] };
    };
  };
}

export const shopsPageQuery = graphql`
  query ShopsPageQuery($language: String!, $dev: Boolean!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
    }
  }
`;

const ShopsPage = ({ data }: Props) => {
  const { t, originalPath, i18n } = useI18next();

  const {
    contentful: {
      stripCollection: { items: strips },
    },
  } = data;

  return (
    <Layout t={t} originalPath={originalPath} i18n={i18n} strips={strips}>
      <section className={styles.hero}>
        <div className={styles.wrapper}>
          <h1>
            <Trans i18nKey="shops-page-title" />
          </h1>
          <h2>
            <Trans i18nKey="shops-page-subtitle" />
          </h2>
        </div>
      </section>
      <section className={styles.container}>
        <div className={styles.content}>
          <div className={styles.box}>
            <h3>{t('maintenance-title')}</h3>
            <hr />
            <p className={styles.main}>{t('maintenance-description')}</p>
            <p className={styles.note}>{t('maintenance-back-soon')}</p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ShopsPage;
